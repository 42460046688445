//
// Forms
//

// Labels

.form-control-label {
  color: $gray-700;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
}

// Text inputs

.form-control {
  font-size: $input-btn-font-size;

  &:focus {
    &::placeholder {
      color: $input-focus-placeholder-color;
    }
  }
}

// Textarea

textarea[resize="none"] {
  resize: none !important;
}

textarea[resize="both"] {
  resize: both !important;
}

textarea[resize="vertical"] {
  resize: vertical !important;
}

textarea[resize="horizontal"] {
  resize: horizontal !important;
}

// Form input variations

// Muted input

.form-control-muted {
  background-color: $input-muted-bg;
  border-color: $input-muted-bg;
  box-shadow: none;

  &:focus {
    background-color: $input-focus-muted-bg;
  }
}

// Alternative input

.form-control-alternative {
  box-shadow: $input-alternative-box-shadow;
  border: 0;
  transition: box-shadow 0.15s ease;

  &:focus {
    box-shadow: $input-focus-alternative-box-shadow;
  }
}

// Size variations: Fixes to the bootstrap defaults

.form-control-lg {
  font-size: $font-size-base;
}

// display read only
.form-control-display {
  background-color: unset !important;
  box-shadow: none !important;
  input,
  select,
  textarea {
    color: #525f7f;
    font-size: 0.875rem;
    font-weight: 400;
    border-radius: 0.375rem;
  }
  input,
  select {
    padding: 0.8243rem 0.75rem;
  }
  fieldset {
    border: unset;
    border-radius: 0.375rem;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
}

// display read only
.form-control-edit {
  background-color: #fff;
  border-radius: 0.375rem;
  input,
  select,
  textarea {
    color: #525f7f;
    font-size: 0.875rem;
    font-weight: 400;
    border-radius: 0.375rem;
  }
  input,
  select {
    padding: 0.8243rem 0.75rem;
  }
  fieldset {
    border: unset;
    border-radius: 0.375rem;
    border: 1px solid rgba(253, 253, 253, 0.05);
  }
  input[type="file"] {
    line-height: 0.7rem;
  }
}
