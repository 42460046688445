
/*------------------*/
/*  SKEW CLOCKWISE  */
/*------------------*/
.skew-cc{
  width:100%;
  height:50px;
  position:absolute;
  left:0px;
  background: linear-gradient(to right bottom, #fdba2b 49%, #f8f9fe 50%),    linear-gradient(-50deg, #ffffff 16px, #000 0);
}


/*-------------------------*/
/* SKEW COUNTER CLOCKWISE  */
/*-------------------------*/
.skew-c{
  width:100%;
  height:50px;
  position:absolute;
  left:0px;
  background: linear-gradient(to left bottom, #f8f9fe 49%, #fdba2b 50%);
}